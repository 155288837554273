
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Lazy load the Home component
const Home = lazy(() => import("./pages/Home"));
const PGI = lazy(() => import("./pages/PGI"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<h1 style={{display:'flex', justifyContent:'center',alignItems:'center',color:'white'}}>Loading...</h1>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pgi" element={<PGI />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;

